import { Component, OnInit, ElementRef, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'], //put non encapuslated styles here
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
    title = 'Hello from Angular';
    entryPoint: string;
    constructor(private router: Router, private location: Location, private elementRef: ElementRef) {
        let native = this.elementRef.nativeElement;
        if (native.getAttribute("initialEntryPoint") == "_localMode") {
            this.entryPoint = location.path();
        }
        else {
            this.entryPoint = native.getAttribute("initialEntryPoint");
        }
    }

    ngOnInit() {
        //checks to see if signout cookie exists
        if (this.checkSession()) {
            this.router.navigateByUrl("/login");
        }
        this.router.navigateByUrl(this.entryPoint.toLowerCase());
    }
    checkSession():boolean {
        return (/^(.*;)?\s*CASIGNOUT\s*=/.test(document.cookie))
    }
}
