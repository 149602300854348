import { Component, OnInit } from '@angular/core';

@Component({
    template: ''
})
export class SystemNotFoundComponent implements OnInit {
    Title: string = "Unexpected Error";
    constructor() {
        window.location.href = '/unexpectedError';
    }
    ngOnInit() { }
}
