import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Service for modal-related concerns.
 */
@Injectable()
export class ModalService {

	private modalVisibilityStream = new Subject<boolean>();

	// Subscribed to by the root component so it can disable page scrolling
	// when a modal is viable.
	modalVisibility$ = this.modalVisibilityStream.asObservable();

	announceModalDisplayed() {
		this.modalVisibilityStream.next(true);
	}

	announceModalDismissed() {
		this.modalVisibilityStream.next(false);
	}
}
