﻿import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//const Investment_Summary_FundInfo_Url = '/SiteApi/Plan/InvestmentSummaryForFundInfo';
const PlanInvestment_FundInfo_Url = '/SiteApi/Plan/PlanInvestmentForFundInfo'; // Project Tiger changes
const PlanFundLifestyle_Url = '/SiteApi/Plan/PlanLifestyleOptions';
const PARAMS = new HttpParams({
    fromObject: {
        format: 'json',
        origin: '*'
    }
});

const valuesPolyfill = function values(object) {
    return Object.keys(object).map(key => object[key]);
}

const values = Object.values || valuesPolyfill

@Injectable()
export class PlanInvestmentsService {

    constructor(private http: HttpClient) {
    }       
   
    getInvestmentSummaryForFundInfo(asOfDate: string): Observable<any> {
        var jsonDateObj = { 'asOfDate': asOfDate }
        return this.http.post(PlanInvestment_FundInfo_Url, jsonDateObj).pipe(map(response => {           
            return response;
        }))
    }

    getPlanFundLifeStyles(asOfDate: string): Observable<any> {
        var jsonDateObj = { 'asOfDate': asOfDate }
        return this.http.post(PlanFundLifestyle_Url, jsonDateObj).pipe(map(response => {
            //console.log(response);
            return response;
        }))
    }
}
   



