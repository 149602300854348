﻿import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import * as Components from '../pages/system';

//import main page components

//routes
const routes: Routes = [
    {
        path: 'myplans',
        loadChildren: '../pages/multiplan/multiplan.module#MultiPlanModule'
    },    {
        path: 'plan',
       loadChildren: '../pages/plan/plan.module#PlanModule'        
    },
    {
        path: 'participant',
        loadChildren: '../pages/participant/participant.module#ParticipantModule'
    },
    { 
        path: 'login',
        loadChildren: '../pages/login/login.module#LoginModule'
    },
    {
        path: 'payrollassist',
        loadChildren: '../pages/payrollassist/payrollassist.module#PayrollAssistModule'
    },
    {
        path: 'financialassist',
        loadChildren: '../pages/financialassist/financialassist.module#FinancialAssistModule'
    },
    {
        path: 'censusassist',
        loadChildren: '../pages/censusassist/censusassist.module#CensusAssistModule'
    },
    {
        path: 'combinedassist',
        loadChildren: '../pages/combinedassist/combinedassist.module#CombinedAssistModule'
    },    
    {
        path: 'system',
        loadChildren: '../pages/system/system.module#SystemModule'
    },
    {
        path: '**', component: Components.SystemNotFoundComponent
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes),
    ],
    exports: [
        RouterModule
    ],
    declarations: []
})
export class AppRoutingModule { }