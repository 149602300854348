
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Http, Response, Headers, RequestOptions, RequestMethod } from "@angular/http";
import { Observable } from 'rxjs';



import { User } from '../../models/user.model'; 


@Injectable()
export class UserService {
    loginResponse: any;
    constructor(private http: HttpClient) { 
    }

    userAuthentication(userName, password, devicefingerprint) {
         
        let usr: User;
        usr = new User();
        usr.UserId = userName;
        usr.Password = password;
        usr.Devicefingerprint = devicefingerprint;
         
        var headerOptions = new Headers({ 'Content-Type': 'application/json' });
        var requestOptions = new RequestOptions({ method: RequestMethod.Post, headers: headerOptions });
        
        return this.http.post('/userlogin/Index', usr).pipe(map(res => res)); 
       
    }

    getLoginCarousel() {
        return this.http.get('/SiteApi/ScContent/Content/').pipe(map(res => res));
    }

    signOut(redirect:boolean) {
        return this.http.post("/SiteApi/Login/SignOutAlt", String(redirect)).pipe(map(res => res));
    }

    get_loginResponse() {
        return this.loginResponse;
    }

    set_loginResponse(response: any) {
        this.loginResponse = response;
    }

    resendCode() {
        return this.http.get('/SiteApi/Login/ReSendSecurityCode/').pipe(map(res => res));
    }

    validateSecCode(secCode) {
        var headerOptions = new Headers({ 'Content-Type': 'application/json' });
        var requestOptions = new RequestOptions({ method: RequestMethod.Post, headers: headerOptions });

        return this.http.post('/SiteApi/Login/ValidateSecurityCode/', String(secCode)).pipe(map(res => res));
    }
}
