import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

//pipes
import * as Pipes from './pipes';
import * as Services from './services';


@NgModule({
  declarations: [
        Pipes.TruncateCurrency,
        Pipes.NullableCurrency,
        Pipes.EscapeHtmlPipe,
  ],
  imports: [
      CommonModule,
      HttpClientModule 
  ],
  exports: [
      Pipes.TruncateCurrency,
      Pipes.NullableCurrency,
      Pipes.EscapeHtmlPipe,
      HttpClientModule 
  ],
  providers: [
      //Services.ParticipantService,
      //Services.PlanService,
      //Services.ModalService,
    ],
})
export class ProvidersModule {
    //for lazy loading
    static forRoot() {
        return {
            ngModule: ProvidersModule,
            providers: [ //services that you want to share across modules
                Pipes.TruncateCurrency,
                Pipes.NullableCurrency,
                Pipes.EscapeHtmlPipe,
                CurrencyPipe,
                DatePipe,
                DecimalPipe,
                Services.ParticipantService,
                Services.PlanService,
                Services.PlanInvestmentsService,
                //Services.MockPlanService,
                Services.SpreadsheetFileService,
                Services.ModalService,
                Services.UtilityService,
                Services.DatepickerService,
            ]
        }
    }
}
