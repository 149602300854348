import { Injectable } from '@angular/core';

// Interfaces
import { SpreadsheetFile } from '../../interfaces/payrollassist.interfaces';

@Injectable()
export class SpreadsheetFileService {
    private uploadedFile: SpreadsheetFile;

    // provides information of the file that was uploaded
    newFileUploaded(newFile: SpreadsheetFile) {
        if (newFile) {
            this.uploadedFile = newFile;
        }
    }

    // Removes the uploaded file from the service.
    // Called when the user clicks a button to re-upload the file.
    removeUploadedFile() {
        this.uploadedFile = undefined;
    }

    // get uploaded file info
    getUploadedFile(): SpreadsheetFile {
        return this.uploadedFile || undefined;
    }
}
