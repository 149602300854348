﻿export class User {
    UserId: string;
    Password: string; 
    Devicefingerprint: string;

    User() {
        this.UserId = "";
        this.Password = "";
        this.Devicefingerprint = "";
    }
}