﻿import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({ name: 'nullableCurrency' })
export class NullableCurrency implements PipeTransform {

    constructor(private currencyPipe: CurrencyPipe) { }

    transform(value: any): string {
        if (value == 0.00) {
            return "";
        }
        return this.currencyPipe.transform(value);
    }
}