import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable,  of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';

const SEARCH_URL = '/SiteApi/Participant/Search'
const REDIRECT_URL = '/SiteApi/ParticipantSelect/SetParticipant'
const RATEOFRETURN_URL = '/SiteApi/Participant/GetRateOfReturn'
const PARTICIPANTKPIS_URL = '/SiteApi/Participant/GetParticipantKPIs'
const PARTICIPANTADDRESS_URL = '/SiteApi/Participant/GetAddressData'
const PARTICIPANTNAME_URL = '/SiteApi/Participant/GetParticipantName'
const PARTICIPANTDEMOGRAPHIC_URL = '/SiteApi/Participant/GetDemographicData'
const VESTINGINFORMATION_URL = '/SiteApi/Participant/GetVestingInformation'
const PARTICIPANTCONTRIBUTIONVESTMENTS_URL = '/SiteApi/Participant/GetParticipantContributionsVestments';
const PPTPLANACCEESS_URL = 'SiteApi/Participant/GetParticipantPlanAccess';
const ADDREHIREPARTICIPANT_URL = 'SiteApi/ParticipantSelect/AddRehire';
const GETACTIVITYHISTORYBYDATE_URL = 'SiteApi/Participant/ActivityHistoryByDate';
const GETSTATEMENTDATA_URL = 'SiteApi/Participant/StatementData';
const GETSTATEMENTDATACSV_URL = 'SiteApi/Participant/StatementDataCsv';
const GETDETAILEDSTATEMENTASOFDATE_URL = 'SiteApi/Participant/GetDetailedStatementAsOfDate';
const GETPARTICIPATIONDATE_URL = 'SiteApi/Participant/GetParticipationDate';
const CANCELTRANSACTION_URL = 'SiteApi/Participant/CancelTransaction';
const GETBCODOMAIN_URL = 'SiteApi/Participant/GetBCODomain';
const GETMODE_URL = 'SiteApi/Participant/GetMode';
const GETARCHIVEDSTATEMENT_URL = 'SiteApi/Participant/GetArchivedStatements';
const GETPPTCUSTOMSTMTFLAG_URL = 'SiteApi/Participant/GetStatementsFlag';
const GETCONTRACTORSTATEMENTS_URL = 'SiteApi/Participant/ContractorStatements';
const GETDOWNLOADSTATEMENT_URL = 'SiteApi/Participant/GetDownloadStatement';
const GETCUSTOMSTATEMENTFILTERS_URL = 'SiteApi/Participant/GetCustomStatementFilters';
const GETPARTCIPANTPLANTYPE_URL = "SiteApi/Participant/GetParticipantPlanType";
const GETPARTICIPANTENDDATE_URL = "SiteApi/Participant/StatementEndDate";
const GETPARTICIPANTSTATEMENTINFO_URL = 'SiteApi/Participant/GetParticipantStatementInfo';

const PARAMS = new HttpParams({
    fromObject: {
        format: 'json',
        origin: '*'
    }
});

const valuesPolyfill = function values(object) {
    return Object.keys(object).map(key => object[key]);
}

const values = Object.values || valuesPolyfill

@Injectable()
export class ParticipantService {

    private participantSearchResults: any;
    constructor(private http: HttpClient) { }


    search(planCode: string,
        term: any,
        isSSN: boolean,
        minChar: number,
        maxResults: number,
        maxResultsMessage: string,
        resultsLinkMessage: string,
        zeroResultsMessage: string,
        minimumCharacterMessage: string,
        ssnSearchMessage: string,
        isMulti: boolean
    ): Observable<Participant[]> {
        if (term.length == 0) {
            return observableOf([]);
        }
        else if (isSSN && term.length != 9) {
            return observableOf([new Participant(null, null, null, null, null, null, ssnSearchMessage, null, null, true)]);
        }
        else if (term.length < minChar) {
            return observableOf([new Participant(null, null, null, null, null, null, minimumCharacterMessage, null, null, true)]);
        }

        var noCommaTerm = term.toString().replace(",", "");

        return this.http
            .get(SEARCH_URL + "?planCode=" + planCode + "&query=" + noCommaTerm + "&isSSN=" + isSSN + "&isMulti=" + isMulti).pipe(
            map(response => {
                //console.log(response);
                //console.log(Object.values(response))
                let participants = new Array<Participant>();
                let _response = values(response);
                _response.forEach(item => {
                    //console.log(item);
                    participants.push(new Participant(
                        item.FirstName,
                        item.HomeUrl,
                        item.LastName,
                        item.PlanCode,
                        item.PlanName,
                        item.Ssn,
                        item.FirstName + " " + item.LastName,
                        item.PersonID,
                        item.AccountID,
                        false
                    ))
                });
                this.setParticipantSearchResults(participants);//save the results
                if (participants.length > maxResults) {
                    participants = participants.slice(0, maxResults);
                    //use fullName for main message body, firstname for link text
                    participants.push(new Participant(resultsLinkMessage, null, null, null, null, null, maxResultsMessage.replace(/{{MAXRESULTS}}/, maxResults.toString()).replace(/{{TOTALRESULTS}}/, _response.length.toString()), null, null, true));
                }
                else if (participants.length == 0) {
                    participants.push(new Participant(null, null, null, null, null, null, zeroResultsMessage, null, null, true));
                }
                //console.log(participants);
                return participants;
            })
            );
    }

    setParticipant(PersonId: string, PlanCode: string, AccountId: string, isMulti: boolean): Observable<any> {
        let postParameters = {
            PersonId: PersonId,
            PlanCode: PlanCode,
            AccountId: AccountId,
            isMulti: isMulti
        };
        return this.http.post(REDIRECT_URL, postParameters).pipe(map(response => {
            //console.log(response);
            return response;
        }))
    }

    getParticipantPlanAccess(PersonId: string): Observable<any> {
        return this.http.post(PPTPLANACCEESS_URL, PersonId).pipe(map(response => {
            //console.log(response);
            return response;
        }))
    }
    getDetailedStatementAsOfDate(): Observable<any> {
        return this.http.post(GETDETAILEDSTATEMENTASOFDATE_URL, null).pipe(map(response => {
            //console.log(response);
            return response;
        }))
    }

    getParticipantEndDate(): Observable<any> {
        return this.http.get(GETPARTICIPANTENDDATE_URL).pipe(map(response => {
            return response;
        }))
    }

    getParticipationDate(PersonId: string): Observable<any> {
        return this.http.post(GETPARTICIPATIONDATE_URL, PersonId).pipe(map(response => {
            //console.log(response);
            return response;
        }))
    }

    getParticipationPlanType(): Observable<any> {
        return this.http.post(GETPARTCIPANTPLANTYPE_URL, null).pipe(map(response => {
            //console.log(response);
            return response;
        }))
    }

    getMode(): Observable<any> {
        return this.http.post(GETMODE_URL,null).pipe(map(response => {
            return response;
        }))
    }

    getBcoDomain(): Observable<any> {
        return this.http.get(GETBCODOMAIN_URL).pipe(map(response => {
            //console.log(response);
            return response;
        }))
    }
    
    getRateOfReturn(PersonId: string): Observable<any> {
        return this.http.post(RATEOFRETURN_URL, PersonId).pipe(map(response => {
            //console.log(response);
            return response;
        }))
    }
    getParticipantKpis(PersonId: string): Observable<any> {
        return this.http.post(PARTICIPANTKPIS_URL, PersonId).pipe(map(response => {
            //console.log(response);
            return response;
        }))
    }
    getParticipantAddress(PersonId: string): Observable<any> {
        return this.http.post(PARTICIPANTADDRESS_URL, PersonId).pipe(map(response => {
            //console.log(response);
            return response;
        }))
    }
    getParticipantName(PersonId: string): Observable<any> {
        return this.http.post(PARTICIPANTNAME_URL, PersonId).pipe(map(response => {
            //console.log(response);
            return response;
        }))
    }

    getParticipantDemographics(PersonId: string): Observable<any> {
        return this.http.post(PARTICIPANTDEMOGRAPHIC_URL, PersonId).pipe(map(response => {
            //console.log(response);
            return response;
        }))
    }
    getParticipantContributionsVestements(PersonId: string): Observable<any> {
        return this.http.post(PARTICIPANTCONTRIBUTIONVESTMENTS_URL, PersonId).pipe(map(response => {
            //console.log(response);
            return response;
        }))
    }

    getParticipantVestingInformation(PersonId: string): Observable<any> {
        return this.http.post(VESTINGINFORMATION_URL, PersonId).pipe(map(response => {
            //console.log(response);
            return response;
        }))
    }
    addRehireParticipant(ssn: string): Observable<any> {
        return this.http.post(ADDREHIREPARTICIPANT_URL, ssn).pipe(map(response => {
            return response;
        }))
    }

    setParticipantSearchResults(results: any) {
        //this.participantSearchResults = JSON.parse(JSON.stringify(results));
        sessionStorage.setItem("ca-searchResults", JSON.stringify(results));

    }
    getParticipantSearchResults() {
        //return this.participantSearchResults
        return JSON.parse(sessionStorage.getItem("ca-searchResults"));
    }
    getActivityHistoryByDate(_startDate: Date, _endDate: Date, _isTestMode , _personId, _isShowCustom): Observable<any> {

        let postParameters = {
            StartDate: _startDate,
            EndDate: _endDate,
            PersonId: _personId,
            TestMode: _isTestMode,
            ShowCustom: _isShowCustom
        };

        return this.http.post(GETACTIVITYHISTORYBYDATE_URL, postParameters).pipe(map(response => {
            console.log("get activity history by date",response);
            return response;
        }));
    }
    getStatementData(_startDate: Date, _endDate: Date, _accounts, _funds, _sortBy, _testMode, _personId, _contributionView) {
        let postParameters = {
            PersonId: _personId,
            StartDate: _startDate,
            EndDate: _endDate,
            Accounts: _accounts,
            Funds: _funds,
            SortBy: _sortBy,
            TestMode: _testMode,
            ContributionView: _contributionView
        };
        return this.http.post(GETSTATEMENTDATA_URL, postParameters)
            .pipe(map(response => {
                return response;
            }));
    }         

    getArchivedStatements(): Observable<any> {
        return this.http.get(GETARCHIVEDSTATEMENT_URL)
            .pipe(map(response => {
            return response;
        }));
    }

    getParticipantStatementsFlag(): Observable<any> {
        return this.http.get(GETPPTCUSTOMSTMTFLAG_URL).pipe(map(response => {
            return response;
        }));
    }

    getStatementFlag() {
        this.http.get(GETPPTCUSTOMSTMTFLAG_URL).subscribe();
    }

    getParticipantStatementInfo() {
        this.http.get(GETPARTICIPANTSTATEMENTINFO_URL).subscribe();
    }

    getContractorStatements(_startDate: Date, _endDate: Date, _isTestMode, _personId) {

        let postParameters = {
            StartDate: _startDate,
            EndDate: _endDate,
            PersonId: _personId,
            TestMode: _isTestMode
        };
        let stringparams = JSON.stringify(postParameters);
        //return this.http.post("http://local.caapi.com/Participant/ContractorStatements", stringparams, { responseType: 'text', headers: }).pipe(map(response => {
        return this.http.post(GETCONTRACTORSTATEMENTS_URL, postParameters).pipe(map(response => {
            return response;
        }));
    }

    getDownloadStatement(_statement: any): Observable<any> {
        let postparams = {
            Filename: _statement.FileName,
            DocId: _statement.DocID,
            Source: _statement.Source,
        };
        
        return this.http.post(GETDOWNLOADSTATEMENT_URL, postparams, { responseType: 'arraybuffer' }).pipe(map(response => {
            //console.log(response);
            return response;
        }));
    }

    getCsvStatement(parameters: any): Observable<any> {
        let postparams = {
            PersonId: parameters.PersonId,
            StartDate: parameters.StartDate,
            EndDate: parameters.EndDate,
            Accounts: parameters.Accounts,
            AccountText: parameters.AccountText,
            AccountCount: parameters.AccountCount,
            Funds: parameters.Funds,
            SortBy: parameters.SortBy,
            TestMode: parameters.TestMode,
            DetailedView: parameters.DetailedView,
            ContributionView: parameters.ContributionView,
        }
        let stringparams = JSON.stringify(postparams);
        return this.http.post(GETSTATEMENTDATACSV_URL, postparams, { responseType: 'text' }).pipe(map(response => {
            return response;
        }));
    }

    cancelTransaction(_personid: string, _transactionNum: string, _transactionType:string): Observable<any> {

        let postParameters = {
            PersonId: _personid,
            TransactionNumber: _transactionNum,
            TransactionType: _transactionType,
        };
        return this.http.post(CANCELTRANSACTION_URL, postParameters).pipe(map(response => {
            //console.log(response);
            return response;
        }));
    }

    getCustomStatementFilters(): Observable<any> {
        return this.http.get(GETCUSTOMSTATEMENTFILTERS_URL).pipe(map(response => {
            //console.log(response);
            return response;
        }));
    }

}
export class Participant {
    constructor(
        _firstName: string,
        _homeUrl: string,
        _lastName: string,
        _planCode: string,
        _planName: string,
        _ssn: string,
        _fullName: string,
        _personId: string,
        _accountId: string,
        _messageOnly:boolean
    ) {
        this.FirstName = _firstName;
        this.HomeUrl = _homeUrl;
        this.LastName = _lastName;
        this.PlanCode = _planCode;
        this.PlanName = _planName;
        this.Ssn = _ssn;
        this.FullName = _fullName;
        this.PersonId = _personId;
        this.AccountId = _accountId;
        this.MessageOnly = _messageOnly;
        if (!_messageOnly) {
            this.displaySsn = "(***-**-" + _ssn.substring(_ssn.length - 4) + ")";
            this.displayPlan = _planCode + " - " + _planName;
        }
    }

    FirstName: string;
    HomeUrl: string;
    LastName: string;
    PlanCode: string;
    PlanName: string;
    Ssn: string;
    FullName: string;
    PersonId: string;
    AccountId: string;
    MessageOnly: boolean;
    displaySsn: string;
    displayPlan: string;

}
