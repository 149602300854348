import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable ,  of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';

const ANALYTICS_URL = '/SiteApi/ScContent/Analytics';
const RORFAQ_URL = '/SiteApi/ScContent/RateOfReturnFAQs';


const PARAMS = new HttpParams({
    fromObject: {
        format: 'json',
        origin: '*'
    }
});

const valuesPolyfill = function values(object) {
    return Object.keys(object).map(key => object[key]);
}

const values = Object.values || valuesPolyfill

@Injectable()
export class UtilityService {
    constructor(private http: HttpClient) { }



    analytics(currentPlan: string, pageTitle: string, participantWeb: string, sponserWeb: string, requestUrl: string, eventAction: string = ""): Observable<any> {
        let postParameters = {
            CurrentPlan: currentPlan,
            PageTitle: pageTitle,
            ParticipantWeb: participantWeb,
            SponsorWeb: sponserWeb,
            RequestUrl: requestUrl,
            EventAction: eventAction
        };
        return this.http.post(ANALYTICS_URL, postParameters).pipe(map(response => {
            return response;
        }))
    }

    GetRateOfReturnFAQs() : Observable<any> {
        return this.http.get(RORFAQ_URL).pipe(map(response => {
            return response;
        }))
    }
}