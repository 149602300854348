﻿import { Injectable, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Observable ,  of as observableOf } from 'rxjs';
import * as moment from 'moment';



@Injectable()
export class DatepickerService {
    today: Date = new Date();
    constructor(private datepipe: DatePipe) { }

    ValidateSelection(_startDate: Date, _endDate: Date, earliestBeginDate: string,
        minDate: Date, maxDate: Date, isContractorStm: boolean = false) {
        var errorCondition = this.checkErrorCondition(_startDate, _endDate, earliestBeginDate, minDate, maxDate, isContractorStm);
        var result = {
            isError: false,
            errorMessage :""
        }
        if (errorCondition != "") {
            result.isError = true;
            result.errorMessage = this.parseErrorMessage(errorCondition, earliestBeginDate, maxDate);
        }

        return result;
    }

    checkErrorCondition(_startDate: Date, _endDate: Date, earliestBeginDate: string,
        minDate: Date, maxDate: Date, isContractorStm: boolean) {
console.log("check error condition:", _startDate, _endDate, earliestBeginDate, minDate, maxDate, isContractorStm);
        if (_startDate == null || _endDate == null) {
            return "DateEmpty";
        }
        if (_startDate < new Date(earliestBeginDate)) {
            return "StartDateBefore";
        }
        if (_startDate < minDate) {
            return "StartDateBefore";
        }
        if (_endDate > maxDate) {
            return "EndDateAfter";
        }
        //non-contractor statement logic only
        if (_startDate > _endDate && !isContractorStm) { 
            return "StartEqualBeforeEnd";
        }
        //contractor statement logic only
        if (_startDate >= _endDate && isContractorStm) {
            return "StartBeforeEnd";
        }
        return "";
    }

    parseErrorMessage(condition: string, earliestBeginDate: string, maxDate: Date) {
        console.log("condition:", condition);
        if (condition == "dateRangeTooLarge") {
            return "Please select a date range of up 12 consecutive months.";
        }

        if (condition == "StartDateBefore") {
            let beginDate = new Date(earliestBeginDate);
            console.log("begin date:", beginDate);
            return "Start Date must be on or after " + this.datepipe.transform(beginDate, 'M/d/yyyy'); 
        }

        if (condition == "EndDateAfter") {
            return " End Date must be on or before " + this.datepipe.transform(maxDate, 'M/d/yyyy');
        }

        if (condition == "StartEqualBeforeEnd") {
            return "Start Date must be on or before End Date.";
        }

        if (condition == "StartBeforeEnd") {
            return "Start Date must be before End Date.";
        }

        if (condition == "DateEmpty") {
            return "Please enter Start and End Dates.";
        } 

        return "";
    }

    getFormattedDate(date: Date) {
        let dateString = date.toDateString();

        dateString = dateString.substring(dateString.indexOf(" ") + 1);
        let dateArray = dateString.split(" ");

        return dateArray[0] + " " + dateArray[1] + ", " + dateArray[2];
    }

    SetStartDate(selectedDate: Date, detailedAsofdate: Date, participationDate: Date) {
        let earliestBeginDate = this.today.toString(); 
        let theStartDate: Date;

        let theDetailedAsofDate = detailedAsofdate;
        let theParticipationDate = participationDate;

        if (theDetailedAsofDate > theParticipationDate) {
            earliestBeginDate = this.getFormattedDate(theDetailedAsofDate);
        }
        else {
            earliestBeginDate = this.getFormattedDate(theParticipationDate);
        }

        if ((selectedDate < theDetailedAsofDate) || (selectedDate < theParticipationDate)) {
            if (theDetailedAsofDate > theParticipationDate) {
                theStartDate = theDetailedAsofDate;
                earliestBeginDate = this.getFormattedDate(theDetailedAsofDate);
            }
            else {
                theStartDate = theParticipationDate;
                earliestBeginDate = this.getFormattedDate(theParticipationDate);
            }
        }
        else {
            theStartDate = selectedDate;
        }

        let result = {
            earliestBeginDate: earliestBeginDate,
            startDate: theStartDate
        }

        return result;
    }

    SetDate(endDate, daysBack?: number) {
        let latestEndDate = this.ParseDate(endDate);
        let maxDate = moment(latestEndDate, 'MM/DD/YYYY').toDate();
        //be careful since date type passed by reference instead of value.
        let _endDate = moment(latestEndDate, 'MM/DD/YYYY').toDate();
        let _startDate = moment(latestEndDate, 'MM/DD/YYYY').toDate();

        if (daysBack == undefined) {
            _startDate.setMonth(_startDate.getUTCMonth() - 12);
        } else {
            _startDate.setUTCDate(_startDate.getUTCDate() - daysBack);
        }

        console.log("start date & _endDate:", _startDate, _endDate);

        let response = {
            latestEndDate: latestEndDate,
            maxDate: maxDate,
            startDate: _startDate,
            endDate: _endDate
        };

        return response;
    }

    ParseDate(dateStr: string) {
        let stripedDateStr = dateStr.replace("/Date(", "").replace(")/", "");
        let dateInt = parseInt(stripedDateStr, 10);

        if (dateInt < 0) {
            return;
        }

        let date = new Date(dateInt);

        return date.toLocaleDateString("en");
    }
}