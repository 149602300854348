﻿import { Pipe, PipeTransform } from "@angular/core"
import { CurrencyPipe } from "@angular/common"
@Pipe({
    name: "truncateCurrency"
})
export class TruncateCurrency implements PipeTransform {

    constructor(private currencyPipe: CurrencyPipe) { }

    transform(value: any): string {
        if (value >= 1000000000) { //1,000,000,000,000.00
            return this.currencyPipe.transform(value / 1000000) + "M"; 
        }
        else if (value >= 1000000) {
            return this.currencyPipe.transform(value / 1000) + "K";
        }
        else {
            return this.currencyPipe.transform(value) ;
        }
    }
}