import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const JHContacts_URL = '/SiteApi/Plan/JHTeamContacts';
const Payroll_Url = '/SiteApi/Plan/Payroll';
const Search_Url = '/SiteApi/Plan/Search';
const Content_Url = '/SiteApi/Plan/Content';
const MultiplanContent_Url = '/SiteApi/Plan/MultiplanContent';
const Investment_Summary_Url = '/SiteApi/Plan/InvestmentSummary';
const Plan_KPIs_Url = '/SiteApi/Plan/PlanKPIs';
const MultiPlan_KPIs_Url = '/SiteApi/Plan/MultiplanKPIs';
const SetCurrentPlan_Url = '/SiteApi/Plan/SetCurrentPlan';
const getRefreshPlan_Url = '/SiteApi/PlanSelect/refreshThePlan';
const getAsOfDatePPT_Url = '/SiteApi/Plan/GetAsOfDatePPT';
const getAsOfDate_Url = '/SiteApi/Plan/GetAsOfDate';
const getPlanInfo_Url = '/SiteApi/Plan/GetPlanInfo';
const getAsOfDateFromSupervisor = '/SiteApi/Plan/GetAsOfDateFromSupervisor';
const getPlanStatementDetails_Url = 'SiteApi/Plan/GetPlanStatementDetails';
const getPlanStatementOptions_Url = 'SiteApi/Plan/GetPlanStatementOptions';
const getPeriodicReports_Url = 'SiteApi/Plan/GetPeriodicReports';
const getMyDocuments_Url = 'SiteApi/Plan/GetMyDocuments';
const sendParticipantForm_Url = 'SiteApi/Plan/SendParticipantForm';
const getPlanForms_Url = 'SiteApi/Plan/GetPlanForms';
const getFormsHistory_Url = 'SiteApi/Plan/GetFormsHistory';
const getFiduciaryService_Url = 'SiteApi/Plan/GetFiduciaryService';
const getNoticData_Url = 'SiteApi/Plan/GetNoticeData';
const getEdeliveryService_Url = 'SiteApi/Plan/GetEdeliveryService';
const updateEdeliveryService_Url = 'SiteApi/Plan/UpdateEdeliveryService';

@Injectable()
export class PlanService {
    constructor(private http: HttpClient) { }

    getAsOfDateFromSupervisor(pType: string) {
        return this.http.post(getAsOfDateFromSupervisor, pType).pipe(map(res => res));
    }

    getJHContacts() {
        return this.http.get(JHContacts_URL).pipe(map(res => res));
    }

    getPayroll() {
        return this.http.get(Payroll_Url).pipe(map(res => res));
    }

    getSearch() {
        return this.http.get(Search_Url).pipe(map(res => res));
    }

    getContent() {
        return this.http.get(Content_Url).pipe(map(res => res));
    }
    getMultiplanContent() {
        return this.http.get(MultiplanContent_Url).pipe(map(res => res));
    }
    getInvestmentSummary() {
        return this.http.get(Investment_Summary_Url).pipe(map(res => res));
    }

    getPlanKPIs() {
        return this.http.get(Plan_KPIs_Url).pipe(map(res => res));
    }
    getMultiplanKPIs() {
        return this.http.post(MultiPlan_KPIs_Url, null).pipe(map(res => res));
    }
    setCurrentPlan(planCode: string) {
        return this.http.post(SetCurrentPlan_Url, planCode).pipe(map(res => res));

    }
    getRefreshPlan(PlanCode: string, currentUrl: string) {
        return this.http.post(getRefreshPlan_Url, { planCode: PlanCode, currentUrl: currentUrl }).pipe(map(res => res));
    }

    getAsOfDate(pType: string) {
        return this.http.post(getAsOfDate_Url, pType).pipe(map(res => res));
    }
    getAsOfDatePPT() {
        return this.http.post(getAsOfDatePPT_Url, null).pipe(map(res => res));
    }

    getPlanInfo() {
        return this.http.get(getPlanInfo_Url).pipe(map(res => res));
    }

    getPlanStatementDetails() {
        this.http.get(getPlanStatementDetails_Url).subscribe();
    }

    getPlanStatementOptions(): Observable<any> {
        return this.http.get(getPlanStatementOptions_Url).pipe(map(response => {
            return response;
        }));
    }

    getPeriodicReports(): Observable<any> {
        return this.http.get(getPeriodicReports_Url).pipe(map(response => {
            return response;
        }));
    }

    getMyDocuments(): Observable<any> {
        return this.http.get(getMyDocuments_Url).pipe(map(response => {
            return response;
        }));
    }

    sendParticipantForm(ParticipantFormInfo: FormData): Observable<any> {
        return this.http.post(sendParticipantForm_Url, ParticipantFormInfo).pipe(map(response => {
            return response;
        }));
    }

    getPlanForms(): Observable<any> {
        return this.http.get(getPlanForms_Url).pipe(map(response => {
            return response;
        }));
    }

    getFiduciaryService(): Observable<any> {
        return this.http.get(getFiduciaryService_Url).pipe(map(response => {
            return response;
        }));
    }

    getFormsHistory(): Observable<any> {
        return this.http.get(getFormsHistory_Url).pipe(map(response => {
            return response;
        }));
    }

    getNoticData() {
        return this.http.post(getNoticData_Url, null).pipe(map(res => res));
    }

    getEdeliveryService(): Observable<any> {
        return this.http.get(getEdeliveryService_Url).pipe(map(response => {
            return response;
        }));
    }

    updateEdeliveryService(selectedCode: string): Observable<any> {
        return this.http.post(updateEdeliveryService_Url, selectedCode).pipe(map(response => {
            return response;
        }));
    }
}