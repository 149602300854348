import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgModule } from '@angular/core';
import { NgbModule, NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing/app-routing.module';

import 'hammerjs';

import { ProvidersModule } from './providers/providers.module';

import { AppComponent } from './app.component';
import { DndModule } from 'ng2-dnd';

//import { DataTableModule } from 'primeng/datatable';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        NgbModule.forRoot(),
        ProvidersModule.forRoot(),
        AppRoutingModule,
        DndModule.forRoot(),
        //DataTableModule,
        TableModule,
        ButtonModule

    ],
    providers: [Title, [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]],
  bootstrap: [AppComponent]
})
export class AppModule { }
